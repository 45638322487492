<template>
    <div>
        <button type="button" :class="{'btn-open-modal': !dataStyle}" @click="openModal" :style="dataStyle"><slot>Ver em 3D na sua casa</slot></button>
        <div class="modal" @keydown.esc="closeModal" :class="{'d-block': showModal, 'd-none': !showModal}">
            <div class="modal-content">
                <button class="close" @click="closeModal">×</button>
                <img class="modal-header" src="https://mobiliar3d.com/img/pages/widget-ar-header.png">
                <img class="img-actions" src="https://mobiliar3d.com/img/pages/product-ar-actions.png">
                <div class="modal-body text-center">
                    <div class="mobiliar-ar-content" :class="{'d-block': isMobile, 'd-none': !isMobile}">
                        <p class="text-center my-5">Clique abaixo para ver o produto em</p>
                        <p style="text-center my-5">
                            <button @click="openAr" class="btn-open-viewer">REALIDADE AUMENTADA</button>
                        </p>
                        <p class="text-center my-5" style="font-size: 0.8rem; color: gray;">Essa tecnologia funciona em diversos modelos de celulares e tablets Android e iOS, mas não em todos.</p>
                    </div>
                    <div class="text-center" :class="{'d-none': isMobile, 'd-block': !isMobile}">
                        <p style="text-center my-5">Faça a leitura do QR CODE abaixo para ver o produto em REALIDADE AUMENTADA</p>
                        <img id="mobiliar-qr-code" class="m-auto" style="width: 200px;" :src="qrCodeUrl">
                    </div>
                </div>
                <div class="modal-footer py-5">
                    <a href="https://mobiliar3d.com/servicos" target="_blank" class="mx-auto">
                        <img src="https://mobiliar3d.com/img/pages/product-ar-developed-by.png" class="mx-auto" style="width: auto; max-height: 3vh;">
                    </a>
                </div>
            </div>
        </div>
        <a v-show="iOS" ref="openARiOS" rel="ar" :href="href"></a>
    </div>
</template>

<script>
export default {
  name: 'ViewerAr',
  props: {
        dataKey: String,
        dataTitle: String,
        dataStyle:  String,
        dataUrl: {
            type: String,
            default: '/ar-incompatible.html'
        },
    },
    data () {
        return {
            actionType: 16,
            showModal: false,
            modelLink: ''       
        }
    },
    mounted() {
        fetch(`${this.host}/api/product/model-url?key=${this.dataKey}`)
            .then(response => response.json())
            .then(data => (this.modelLink = data));
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        logAction() {
            fetch(`${this.host}/api/log/save?action=${this.actionType}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: `product_key=${this.dataKey}`
            });
        },
        openAr() {
            // this.logAction();

            if (this.Android)
                this.openARCoreViewer();
            else if (this.iOS)
                this.openIOSViewer();
        },
        openIOSViewer() {
            this.$refs.openARiOS.click();
        },
        openARCoreViewer() {
            
            const fallbackUrl = encodeURIComponent(this.dataUrl);
            const file = encodeURIComponent(this.modelLink);
            const title = encodeURIComponent(this.dataTitle);

            const intent = `intent://arvr.google.com/scene-viewer/1.0?file=${file}&title=${title}&resizable=false#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${fallbackUrl};end;`;

            window.location.href = intent;
        },         
    },
    computed: {
        host: function () {
            return window.location.hostname == 'localhost' ? 'http://localhost/mobiliar/web/index.php' : 'https://archabox.com';
        },
        qrCodeUrl: function () {
            return `${this.host}/product/${this.dataKey}/qr-code.jpg`;
        },
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        iOS: function () {
            return (navigator.userAgent.match(/iPhone|iPad|iPod/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
        },
        isMobile: function( ) {
            return (this.Android || this.iOS);
        },
        href: function () {
            return `${this.modelLink}#allowsContentScaling=0'`;
        }
    },  
}
</script>

<style scoped>
.text-center {
    text-align: center;
}
.py-5 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.my-5 {
    margin-top: 15px;
    margin-bottom: 15px;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.d-block {
    display: block;
}
.d-none {
    display: none;
}
button.btn-open-modal {
    margin:auto; 
    border: none; 
    display: block; 
    font-size: 16px; 
    margin: 4px 2px; 
    cursor: pointer; 
    color: #444444; 
    font-weight: bold; 
    padding: 20px 30px; 
    border-radius: 4px; 
    text-align: center; 
    background-color: #fdd84d; 
    box-shadow: 3px 3px 3px #999999;
}
button.ar-viewer:focus {
    outline: 0;
}
.modal {
    top: 0px; 
    left: 0px; 
    right: 0px; 
    opacity: 1; 
    bottom: 0px; 
    z-index: 9999;
    position: fixed; 
    text-align: left; 
    visibility: visible; 
    font-family: sans-serif; 
    background: rgba(0, 0, 0, 0.9); 
    transition: opacity 0.25s ease 0s; 
}
.modal-content {
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    width: 80%; 
    margin: auto; 
    overflow: auto; 
    font-size: 1rem; 
    max-height: 90%;
    max-width: 720px; 
    border-radius: 5px; 
    position: absolute; 
    transition: top .25s ease; 
    background-color: #f1f1f2; 
    font-family: 'Open Sans',sans-serif; 
}
button.close {
    top: 0.2em; 
    right: 0.2em; 
    border: none; 
    color: gray;
    font-size: 2rem; 
    cursor: pointer;
    position: absolute; 
    background: transparent; 
}
button.close:focus {
    outline: 0;
    color: black;
}
.modal-header {
    width:auto; 
    margin:auto;
    display:block; 
    max-width:100%; 
    max-height:35%; 
}
.img-actions {
    width:auto; 
    display:block; 
    max-height:10%; 
    margin: 20px auto;
}
.modal-body {
    color: #333;
    padding: 0 15px;
    position: relative; 
}
.btn-open-viewer {
    border: none; 
    margin: auto; 
    display: block; 
    font-size: 16px; 
    cursor: pointer; 
    color: #444444; 
    font-weight: bold; 
    padding: 20px 30px; 
    text-align: center; 
    border-radius: 4px; 
    background-color: #fdd84d; 
    box-shadow: 3px 3px 3px #999999;
}
.modal-footer {
    bottom: 0; 
    width: 100%; 
    height: auto; 
    display: flex; 
    position: absolute; 
    align-items: baseline; 
    background: #151514;
}
</style>
