<template>
    <div>
        <button type="button" :class="{'btn-open-modal': !dataStyle}" :style="dataStyle" @click="openModal"><slot>Abrir Viewer 360</slot></button>
        <div class="modal" ref="modal" :class="{'d-block': showModal }">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="close" @click="closeModal">x</button>
                        <viewer360 :data-key="dataKey"></viewer360>
                    </div>
                </div>
            </div>            
        </div>    
        <div class="modal-backdrop" v-if="showModal" :class="{show: showModal }"></div>    
    </div>
</template>

<script>
import Viewer360 from './Viewer360'

export default {
    name: 'Viewer360Modal',
    components: {
      Viewer360
    },
    props: {
        dataKey: String,
        dataStyle: String        
    },
    data () {
        return {
            showModal: false                
        }
    },
    mounted() {
        const modal = this.$refs.modal;
        let self = this;
        modal.onclick = function(event) {
            if (event.target == modal)
                self.closeModal();
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        }
    },
    computed: {
        
    },  
}
</script>
<style lang="scss" scoped>
.hide {
    display: none;
    visibility: hidden;
}
.show {
    opacity: 0.5;
}
.d-block {
    display: block !important;
}
button.btn-open-modal {
    margin:auto; 
    border: none; 
    display: block; 
    font-size: 16px; 
    margin: 4px 2px; 
    cursor: pointer; 
    color: #444444; 
    font-weight: bold; 
    padding: 20px 30px; 
    border-radius: 4px; 
    text-align: center; 
    background-color: #fdd84d; 
    box-shadow: 3px 3px 3px #999999;
}
button.btn-open-modal:focus {
    outline: none;
}
.modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 0;    
    display: none;
    z-index: 99999;
    position: fixed;
    overflow: hidden;    
}
.modal-dialog {
    width: auto;
    margin: .5rem;
    display: flex;
    max-width: 1140px;
    position: relative;
    align-items: center;
    pointer-events: none !important;
    min-height: calc(100% - 3.5rem);
}
.modal-content {
    outline: 0;
    width: 100%;
    display: flex;
    pointer-events: auto;
    flex-direction: column;
    background-color: #fff;
}
.modal-header {
    background: #fff;
}
.modal-body {
    height: 100%;
    display: flex;
    position: relative;  
    background: #fff;
    align-items: center;
    justify-content: center;
}
.close {
    right: 0;
    top: -5px;
    width: 30px;
    z-index: 10;
    border: none;
    color: black;
    margin-top: 5px;
    font-size: 16px;
    cursor: pointer;
    line-height: 30px;
    text-align: center;
    position: absolute;
}
.close:focus {
    outline: none;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99998;
    background: rgba(0,0,0);
    transition: opacity .15s linear;
}
@media screen and (min-width: 1140px) {
    .modal-dialog {
         margin: 1.75rem auto;
    }
}
</style>
