<template>
    <div v-if="dataKey" v-observe-visibility="{
            callback: showOff,
            once: true,
        }"
        id="viewer360"
        ref="container"
        class="cloudimage-360"
        :data-folder="folder"
        data-filename="{index}.jpg"
        data-amount="32">
    </div>    
</template>

<script>
 /* eslint-disable */
import Vue from 'vue'
import ci360 from './ci360/index.js';
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility)

export default {
  name: 'Viewer360',
  props: {
    dataKey: String
  },
  data () {
        return {
            actionType: 17,        
        }
    },
  mounted() {
      
  },
  methods: {
      showOff(isVisible, entry) {
          if (isVisible && this.dataKey) {
            window.CI360.init(this.$refs.container);
            // this.logAction();
          }
      },
      logAction() {
          const url = `${this.host}/api/log/save?action=${this.actionType}`;
          fetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: `product_key=${this.dataKey}`
          });
      }
  },
  computed: {
      folder: function () {
          return  `https://mobiliar.nyc3.cdn.digitaloceanspaces.com/assets/product/${this.dataKey}/viewer360/hd/`
      },
      host: function () {
          return window.location.hostname === 'localhost' ? 'http://localhost/mobiliar/web/index.php' : 'https://mobiliar3d.com';
      },
      ambient: function () {
        return window.location.hostname === 'localhost' ? 'dev' : 'prod';
      }
  },  
}
</script>

<style scoped>
.cloudimage-360 {
    margin: auto;
    max-width: 85vmin; 
}
</style>
